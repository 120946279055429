import React, { useState } from 'react'
import { Link } from 'gatsby'
import './nav.scss'
import './nav-sticky'
import logo from '../images/logo2.png'

export default function Nav() {
    const [showMenu, setShowMenu] = useState('no_active')

    function handleClick() {
        if (showMenu === 'no_active') {
            setShowMenu('active')
        } else {
            setShowMenu('no_active')
        }
    }

    return (
        <div>
            <div id="nav_button" className={showMenu} onClick={handleClick} onKeyDown={handleClick} role="button" tabIndex="0">
                <div className="nav_spans">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <nav id="navbar" className={showMenu}>
                <div className="div_navbar">
                    <img src={logo} alt="Logo La Cité de l'évasion" />
                    <Link to="/" activeClassName="active" className="lien_nav"><span className="texte_lien_span">Accueil</span></Link>
                    <Link to="/voyages" activeClassName="active" className="lien_nav"><span className="texte_lien_span">Voyages</span></Link>
                    <Link to="/sorties" activeClassName="active" className="lien_nav"><span className="texte_lien_span">Sorties</span></Link>
                    <Link to="/contact" activeClassName="active" className="lien_nav"><span className="texte_lien_span">Contact</span></Link>
                </div>
            </nav>
            <div id="fond_nav" className={showMenu} onClick={handleClick} onKeyDown={handleClick} role="button" tabIndex="0">fond</div>
        </div>
    )
}