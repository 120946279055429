var prevScrollpos = window.pageYOffset;
  window.onscroll = function() {

  var currentScrollpos = window.pageYOffset;
  var navBar = document.getElementById("navbar");
  var lienNav = document.querySelector(".div_navbar");
  if(prevScrollpos < 500) {
    navBar.classList.remove('top_100');
    navBar.classList.add('top_0');
    navBar.classList.add('background_white');
    lienNav.classList.add('lien_sticky');
  } else if (prevScrollpos > currentScrollpos) {
    navBar.classList.remove('top_100');
    navBar.classList.add('top_0');
    navBar.classList.add('background_white');
    lienNav.classList.add('lien_sticky');
  } else {
    navBar.classList.remove('top_0');
    navBar.classList.add('top_100');
  }
  if(currentScrollpos === 0) {
    navBar.classList.remove('background_white');
    lienNav.classList.remove('lien_sticky');
  }
  prevScrollpos = currentScrollpos;
}