import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faPhone, faPlane, faMailBulk, faBus } from '@fortawesome/free-solid-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import './footer.scss'

export default function Footer() {
    return (
        <div className="footer">
            <div className="container">
            <div className="blocs">
                <div className="bloc1">
                    <p>Suivez-nous</p>
                    <div>
                        <a href="https://www.facebook.com/citedelevasion" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faFacebook} /></a>
                    </div>
                </div>

                <div className="bloc2">
                        <Link to="/" className="primary_a_svg"><span className="iconSpan"><FontAwesomeIcon icon={faHome} /></span>Accueil</Link>
                        <Link to="/voyages" className="primary_a_svg"><span className="iconSpan"><FontAwesomeIcon icon={faPlane} /></span>Voyages</Link>
                        <Link to="/sorties" className="primary_a_svg"><span className="iconSpan"><FontAwesomeIcon icon={faBus} /></span>Sorties</Link>
                        <Link to="/contact" className="primary_a_svg"><span className="iconSpan"><FontAwesomeIcon icon={faPhone} /></span>Contact</Link>
                </div>

                <div className="bloc3">
                    <div className="coordonnees">
                        <a href="tel:+33479353242" className="tel_contact"><span className="iconSpan"><FontAwesomeIcon icon={faPhone} /></span>04 79 35 32 42</a>
                        <p><a href="mailto:contact@citedelevasion.com"><span className="iconSpan"><FontAwesomeIcon icon={faMailBulk} /></span>contact@citedelevasion.com</a></p>
                        <p>725, boulevard Barrier<br />
                        73100 AIX LES BAINS</p>
                    </div>
                </div>
            </div>
            
            <div className="copyright">
                <p>2021 © Cité de l'Évasion - <Link to="/mentions-legales">Mentions Légales</Link></p>
            </div>
            </div>
        </div>
    )

}